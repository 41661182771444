<template>
  <div>
    <iframe
      title="supportTicket"
      width="100%"
      height="700"
      :src="link"
      style="border: none;"
      allowfullscreen
      webkitallowfullscreen
      mozallowfullscreen
      msallowfullscreen
    >
    </iframe>
  </div>
</template>
<script>
export default {
  data(){
    return {
      link: 'https://forms.office.com/Pages/ResponsePage.aspx?id=gCk4asRa80SwTvoFs4u3hmAHlJ1tTbBArpjjh1TqBBVUMUZQWDRJUkZSUDdDWjNLNVFDQUo5NUpORi4u&embed=true'
    }
  }
}
</script>
